/* eslint-disable no-underscore-dangle */
import React, { useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Image from "../../components/common/Image";

export default function JackHenryCard(props) {
  return (
    <JHCardWrapper>
      <Helmet>
        <script type="text/javascript">
          {`
           class e {
            constructor(a) {
              this.type = a;
            }
          }
          const f = { ANDROID: "bannoAndroidBridge", IOS: "bannoIOSBridge" };
          let g;
          function h(a) {
            a.ports &&
              a.ports[0] &&
              (([g] = a.ports), window.removeEventListener("message", h));
          }
          window.addEventListener("message", h);
          function k(a) {
            if (g) g.postMessage(JSON.stringify(a));
            else if (window[f.ANDROID] && window[f.ANDROID].postMessage)
              window[f.ANDROID].postMessage(JSON.stringify(a));
            else if (window.webkit && window.webkit.messageHandlers[f.IOS])
              window.webkit.messageHandlers[f.IOS].postMessage(a);
            else if (window.parent !== window) window.parent.postMessage(a, "*");
            else return !1;
            return !0;
          }
          class l extends e {
            constructor(a, d = !1) {
              super("click-link");
              this.data = { href: a, external: d };
            }
          }
          class n extends e {
            constructor(a) {
              super("request-resize");
              this.data = { height: a };
            }
          }
          const p = { SAME_WINDOW: 0, NEW_WINDOW: 1 };
          function q(a) {
            if ("click" === a.type) {
              var d = p.SAME_WINDOW;
              if (1 === (null === a.which ? a.button : a.which)) {
                if (a.metaKey || a.ctrlKey || a.shiftKey) d = p.NEW_WINDOW;
                if (!a.defaultPrevented) {
                  var c = a.target;
                  if ("A" !== c.nodeName) {
                    var b = a.composedPath();
                    for (
                      let m = 0;
                      m < b.length && ((c = b[m]), "A" !== c.nodeName);
                      m += 1
                    );
                  }
                  if (
                    c &&
                    "A" === c.nodeName &&
                    ((b = c),
                    !b.hasAttribute("download") &&
                      "external" !== b.getAttribute("rel") &&
                      (c = b.getAttribute("href")) &&
                      ((b.pathname !== window.location.pathname && "" !== b.pathname) ||
                        (!b.hash && "#" !== c)) &&
                      (!(b.protocol && 0 < b.protocol.length) ||
                        /^https?:$/.test(b.protocol)))
                  ) {
                    b = b.target ? b.target.toUpperCase() : null;
                    if ("_BLANK" === b) d = p.NEW_WINDOW;
                    else if ("_TOP" !== b) return;
                    c = new URL(c, window.location.href);
                    !1 !== k(new l(c.toString(), d === p.NEW_WINDOW)) &&
                      a.preventDefault();
                  }
                }
              }
            }
          }
          const r = {
            REQUEST_CLOSE: "request-close",
            REQUEST_SYNC: "request-sync",
            PING: "ping",
            READY: "ready",
          };
          function t() {
            k(new e(r.PING));
          }
          function u(a) {
            a.detail.originalEvent.preventDefault();
            k(new l(a.detail.href));
          }
          if (window !== top) {
            document.addEventListener("click-link", u, !1);
            const a = () => {
              t();
            };
            document.addEventListener("mousedown", a, { passive: !0 });
            document.addEventListener("keydown", a, { passive: !0 });
            document.addEventListener(
              "visibilitychange",
              () => {
                !1 === document.hidden && t();
              },
              { passive: !0 }
            );
          }
          class v {
            constructor() {
              this.listeners = {};
            }
            addEventListener(a, d) {
              this.listeners[a] = this.listeners[a] ? this.listeners[a].concat([d]) : [d];
            }
            removeEventListener(a, d) {
              this.listeners[a] = this.listeners[a].filter((c) => c !== d);
            }
            dispatchEvent(a) {
              const { type: d } = a;
              (this.listeners[d] || []).forEach((c) => c(a));
              return !a.defaultPrevented;
            }
          }
          const w = new v();
          window.addEventListener("message", (a) => {
            w.dispatchEvent(new CustomEvent(a.data.type, { detail: a.data.data }));
          });
          const requestClose = function () {
            k(new e(r.REQUEST_CLOSE));
          };
          const requestSync = function () {
            k(new e(r.REQUEST_SYNC));
          };
          const postUniversalMessage = k;
          const ping = t;
          const ready = function () {
            k(new e(r.READY));
          };
          const requestResize = function (a) {
            k(new n(a));
          };
          const host = w;
          const HOST_EVENTS = {
            HISTORY_BACK: "history-back",
            HISTORY_FORWARD: "history-forward",
          };
          const enableRouter = function () {
            document.addEventListener("click", q, !1);
          };
          // export {
          //   requestClose,
          //   requestSync,
          //   postUniversalMessage,
          //   ping,
          //   ready,
          //   requestResize,
          //   host,
          //   HOST_EVENTS,
          //   enableRouter,
          // };
     
          enableRouter();
            
            `}
        </script>
      </Helmet>
      <JHCardBody>
        <JHCardImageWrapper>
          <a target="_top" href="https://uprise.us">
            <Image
              src="jh/jack-henry.jpg"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </a>
        </JHCardImageWrapper>
      </JHCardBody>
    </JHCardWrapper>
  );
}

const JHCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const JHCardBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const JHCardImageWrapper = styled.div`
  width: 100%;
`;
